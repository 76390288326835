import React, { useState, useEffect, useRef } from 'react';
import { Layout } from 'antd';
import ChatSider from './ChatSider';
import ChatContent from './ChatContent';
import { getMessagesForConversation, getMessages, addMessage, fetchPaginatedMessages, fetchPaginatedUsers, updateUserConversation } from '../../features/chat/ChatService';

const conversationId = '6729928353c1b4433d320e80';
const helperUserId = '6729928353c1b4433d320e80';

const ChatMessages = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [hasMoreUsers, sethasMoreUsers] = useState(true);

    const messagesUnsubscribeRef = useRef(null);
    const unsubscribeRef = useRef(null);

    useEffect(() => {
        if (messagesUnsubscribeRef.current) {
            messagesUnsubscribeRef.current();
        }
        if (conversationId) {
            messagesUnsubscribeRef.current = getMessagesForConversation(conversationId, (users) => {
                setUsers(users);
                if (users.length > 0) {
                    let usr = users[0];
                    setSelectedUser(usr);
                    setHasMoreMessages(true);
                    loadInitialMessages(usr);
                }
            });
        }

        return () => {
            if (messagesUnsubscribeRef.current) {
                messagesUnsubscribeRef.current();
            }
        };
    }, [conversationId]);

    const handleUserSelect = async (user) => {
        setSelectedUser(user);
        setHasMoreMessages(true);
        loadInitialMessages(user);
        await updateUserConversation(conversationId, user);
    };

    const loadInitialMessages = async (user) => {
        if (unsubscribeRef.current) {
            unsubscribeRef.current();
        }

        unsubscribeRef.current = getMessages(user.id, null, (msgs) => {
            setMessages(msgs);
            if (msgs.length < 10) {
                setHasMoreMessages(false);
            }
        });
    }

    const handleLoadMore = async () => {
        if (!hasMoreMessages || !selectedUser || messages.length === 0) return;

        const lastMessageRef = messages[0].docRef;
        const newMessages = await fetchPaginatedMessages(selectedUser.id, lastMessageRef);

        if (newMessages.length === 0) {
            setHasMoreMessages(false);
        } else {
            setMessages([...newMessages, ...messages]);
        }
    };


    const handleLoadMoreUsers = async () => {
        if (!hasMoreUsers || users.length === 0) return;

        const lastUserRef = users[users.length - 1].docRef;
        const newUsers = await fetchPaginatedUsers(conversationId, lastUserRef);

        if (newUsers.length === 0) {
            sethasMoreUsers(false);
        } else {
            setUsers([...users, ...newUsers]);
        }
    };

    const handleSendMessage = () => {
        if (message.trim()) {
            const msg = {
                message,
                senderId: helperUserId,
                receiverId: selectedUser.id,
                seen: true,
            };
            addMessage(msg);
            setMessage('');
        }
    };

    return (
        <Layout style={{ height: '80vh' }}>
            <ChatSider users={users} selectedUser={selectedUser} onSelectUser={handleUserSelect} handleLoadMoreUsers={handleLoadMoreUsers} hasMoreUsers={hasMoreUsers} />
            <ChatContent
                selectedUser={selectedUser}
                messages={messages}
                message={message}
                setMessage={setMessage}
                hasMoreMessages={hasMoreMessages}
                handleLoadMore={handleLoadMore}
                handleSendMessage={handleSendMessage}
            />
        </Layout>
    );
};

export default ChatMessages;
