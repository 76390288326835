export function FormattedTimestamp({ timestamp }) {
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);

    const today = new Date();
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);

    let formattedDate;

    if (date.toDateString() === today.toDateString()) {
        formattedDate = `Today, ${date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
        formattedDate = `Yesterday, ${date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}`;
    } else {
        formattedDate = date.toLocaleString('en-US', {
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        });
    }

    return (
        <div style={{ fontSize: "10px" }}>{formattedDate}</div>
    );
}