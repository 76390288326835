import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB3Wef5H4e6QTXNkKFUTkAQ0mkAZr5g6nA",
    authDomain: "ibizaonlyapp-e60ed.firebaseapp.com",
    databaseURL: "https://ibizaonlyapp-e60ed-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ibizaonlyapp-e60ed",
    storageBucket: "ibizaonlyapp-e60ed.firebasestorage.app",
    messagingSenderId: "377077488870",
    appId: "1:377077488870:web:36c32545ae3cf8de47af27"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);
