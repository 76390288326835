import React, { useRef } from 'react';
import { Layout, Input, Typography } from 'antd';
import { FormattedTimestamp } from '../../utils/utility';

const { Content, Header } = Layout;
const { Text } = Typography;

const ChatContent = ({
    selectedUser,
    messages,
    message,
    setMessage,
    hasMoreMessages,
    handleLoadMore,
    handleSendMessage,
}) => {
    const scrollRef = useRef(null);

    return (
        <Content style={{ padding: '24px' }}>
            {selectedUser ? (
                <>
                    <Header style={{ background: '#fff', padding: '10px' }}>
                        <Text strong>{selectedUser.userName}</Text>
                    </Header>

                    {hasMoreMessages && (
                        <div style={{ textAlign: 'center', margin: '16px 0' }}>
                            <a
                                onClick={handleLoadMore}
                                style={{ cursor: 'pointer', color: '#1890ff', textDecoration: 'underline' }}
                            >
                                Load more messages
                            </a>
                        </div>
                    )}

                    <div
                        ref={scrollRef}
                        style={{
                            height: '70vh',
                            overflowY: 'scroll',
                            backgroundColor: '#f0f2f5',
                            padding: '16px',
                            marginBottom: '16px',
                            borderRadius: '4px',
                        }}
                    >
                        {messages.map((msg, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    justifyContent: msg.senderId === selectedUser.id ? 'flex-start' : 'flex-end',
                                    marginBottom: '8px',
                                }}
                            >
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: msg.senderId === selectedUser.id ? '#e8e8e8' : '#1890ff',
                                            color: msg.senderId === selectedUser.id ? '#000' : '#fff',
                                            padding: '8px 12px',
                                            borderRadius: '20px',
                                        }}
                                    >
                                        <span>{msg.message}</span>
                                    </div>
                                    <div>
                                        <FormattedTimestamp timestamp={msg.timestamp} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <Input.Search
                        placeholder="Type a message..."
                        enterButton="Send"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onSearch={handleSendMessage}
                    />
                </>
            ) : (
                <Text>Please select a user to start chatting.</Text>
            )}
        </Content>
    );
};

export default ChatContent;
