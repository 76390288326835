import { collection, query, getDocs, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { db } from './../../utils/firebase';
import { limit, startAfter, orderBy, addDoc } from "firebase/firestore"
const supportUserId = '6729928353c1b4433d320e80';

export async function fetchPaginatedMessages(userId, startAfterDoc, limitNum = 20) {
    try {
        const q = query(
            collection(db, `messages/${supportUserId}/${userId}`),
            orderBy('timestamp', 'desc'),
            startAfterDoc ? startAfter(startAfterDoc) : undefined,
            limit(limitNum)
        );
        const querySnapshot = await getDocs(q);
        const messages = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            docRef: doc,
        }));
        messages.reverse();

        return messages;

    } catch (error) {
        console.error("Error fetching messages:", error);
        return [];
    }
};

export function getMessages(userId, startAfterDoc, callback, limitNum = 10,) {
    try {
        const messagesRef = collection(db, `messages/${supportUserId}/${userId}`);

        // Build the query with ordering and limit
        let queryString = query(messagesRef, orderBy('timestamp', 'desc'), limit(limitNum));

        // If a startAfterDoc is provided, start the query after that document
        if (startAfterDoc) {
            queryString = query(queryString, startAfter(startAfterDoc));
        }

        // Set up the real-time listener
        const unsubscribe = onSnapshot(queryString, (querySnapshot) => {
            const messages = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                docRef: doc,
            }));

            // Reverse messages array for oldest messages first
            messages.reverse();

            // Pass the messages to the callback function
            callback(messages);
        });

        // Return the unsubscribe function to stop listening
        return unsubscribe;

    } catch (error) {
        console.error("Error setting up real-time listener:", error);
        callback([]);  // Pass an empty array on error
    }
}

export function getMessagesForConversation(conversationId, callback, limitNum = 10) {
    try {
        // Reference to the 'messages' subcollection within the specific conversation
        const messagesRef = collection(db, `conversations/${conversationId}/messages`);

        // Create a query with Firestore's orderBy to sort by unseen_count
        const messagesQuery = query(messagesRef, orderBy('unseen_count', 'desc'), limit(limitNum));

        // Set up the real-time listener
        const unsubscribe = onSnapshot(messagesQuery, (querySnapshot) => {
            const messages = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                docRef: doc,
            }));

            // Pass the sorted messages to the callback function to update the component state
            callback(messages);
        });

        // Return the unsubscribe function to allow stopping the listener when needed
        return unsubscribe;

    } catch (error) {
        console.error("Error fetching messages:", error);
        callback([]); // Return empty array on error
    }
}

export async function fetchPaginatedUsers(conversationId, startAfterDoc, limitNum = 20) {
    try {
        const q = query(
            collection(db, `conversations/${conversationId}/messages`),
            orderBy('unseen_count', 'desc'),
            startAfterDoc ? startAfter(startAfterDoc) : undefined,
            limit(limitNum)
        );
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            docRef: doc,
        }));

        return users;

    } catch (error) {
        console.error("Error fetching messages:", error);
        return [];
    }
}

export async function updateUserConversation(conversationId, user) {
    try {
        // Update unseen_count to 0 and seen to true
        if (user.unseen_count > 0 || user.seen === false) {
            const userDocRef = doc(db, `conversations/${conversationId}/messages`, user.id);
            await updateDoc(userDocRef, {
                unseen_count: 0,
                seen: true
            });
        }
        return;
    } catch (error) {
        console.error("Error updating user conversation:", error);
        return [];
    }
}



export async function addMessage(msg) {
    try {
        /** Send to self */
        const messagesRef = collection(db, `messages/${msg.senderId}/${msg.receiverId}`);
        const docRef = await addDoc(messagesRef, {
            message: msg.message,
            receiverId: msg.receiverId,
            seen: true,
            senderId: msg.senderId,
            timestamp: new Date(),
        });
        /** Send to receiver */
        const messagesRecRef = collection(db, `messages/${msg.receiverId}/${msg.senderId}`);
        const docRecRef = await addDoc(messagesRecRef, {
            message: msg.message,
            receiverId: msg.receiverId,
            seen: false,
            senderId: msg.senderId,
            timestamp: new Date(),
        });
    } catch (error) {
        console.error("Error adding message:", error);
    }
}