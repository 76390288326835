import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message as msg } from 'antd';
import { getUsers, sendNotification, clearMessage } from "./../features/notification/notificationSlice";
import { Button, Form, Input } from 'antd';
import debounce from 'lodash/debounce';
import { Select, Spin } from 'antd';
const { Option } = Select;

const Setting = () => {
  const [messageApi, contextHolder] = msg.useMessage();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isError, sending, message, isSendSuccess, users, loading } = useSelector((state) => state.notification);

  const debouncedFetchOptions = debounce((value) => {
    if (value) {
      dispatch(getUsers({ key: value }));
    }
  }, 300);

  const handleSearch = (value) => {
    debouncedFetchOptions(value);
  };

  /** Check for api error and call api for init data */
  useEffect(() => {
    if (users.length === 0) {
      dispatch(getUsers({ key: '' }));
    }
    if (isError && message) {
      messageApi.open({
        type: 'error',
        content: message,
      });
      dispatch(clearMessage());
    }
    if (isSendSuccess) {
      messageApi.open({
        type: 'success',
        content: "Notification is sent successfully",
      });
    }
    form.setFieldsValue({
      title: '',
      body: '',
      user: '',
    });
  }, [isError, message, messageApi, isSendSuccess, form, dispatch]);


  /** On submit call api */
  const onFinish = (values) => {
    const convertedValues = {
      ...values,
    };
    convertedValues.type = 3;
    console.log('Converted Values:', convertedValues);
    dispatch(sendNotification(convertedValues));
  };

  /** If there is error on form submission */
  const onFinishFailed = (errorInfo) => {
    messageApi.open({
      type: 'error',
      content: errorInfo.message || 'Error on form submission',
    });
  };

  return (
    <>
      {contextHolder}
      <div>
        <h3 className="mb-4 title">Push Notification</h3>
        <div className="d-flex gap-3">
          <div className="d-flex flex-grow-1 bg-white p-3 roudned-3">
            <div className="p-4 bg-white rounded-3" style={{ width: "60%" }}>
              <Form
                form={form}
                name="basic"
                layout="vertical"  // Set the layout to vertical
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >

                <Form.Item
                  label="Notification title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'Please input notification title',
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>

                <Form.Item
                  label="Notification body"
                  name="body"
                  rules={[
                    {
                      required: true,
                      message: 'Please input notification body',
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>

                <Form.Item
                  name="user_id"
                  label="User"
                  rules={[{ required: true, message: 'Please select a user' }]}
                >
                  <Select
                    showSearch
                    placeholder="Type to search"
                    notFoundContent={loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={handleSearch}
                    allowClear
                  >
                    <Option key="all" value="all">
                      All
                    </Option>

                    {users.map((option) => (
                      <Option key={option.id} value={option.value}>
                        {option.first_name} {option.last_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button disabled={sending} type="primary" htmlType="submit">
                    {sending ? 'Sending ...' : 'Send'}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
