import React from 'react';
import { List, Avatar, Badge, Layout, Typography } from 'antd';

const { Sider, Header } = Layout;
const { Text } = Typography;

const ChatSider = ({ users, selectedUser, onSelectUser, handleLoadMoreUsers, hasMoreUsers }) => {

    return (
        <Sider width={250} theme="light">
            <Header style={{ background: '#fff', padding: '10px' }}>
                <Text strong>Users</Text>
            </Header>
            <List
                itemLayout="horizontal"
                dataSource={users}
                renderItem={(user) => (
                    <List.Item
                        onClick={() => onSelectUser(user)}
                        style={{
                            cursor: 'pointer',
                            background: selectedUser?.id === user.id ? '#e6f7ff' : 'transparent',
                        }}
                    >
                        <List.Item.Meta
                            style={{ paddingLeft: '8px' }}
                            avatar={<Avatar src={user.avatarURL} />}
                            title={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {user.userName}
                                    {(!user.seen || user.unseen_count > 0) && (
                                        <Badge
                                            count={user.unseen_count}
                                            style={{ backgroundColor: '#52c41a', marginLeft: '8px' }}
                                        />
                                    )}
                                </div>
                            }
                        />
                    </List.Item>
                )}
            />

            {hasMoreUsers && (
                <div style={{ textAlign: 'center', margin: '16px 0' }}>
                    <a
                        onClick={handleLoadMoreUsers}
                        style={{ cursor: 'pointer', color: '#1890ff', textDecoration: 'underline' }}
                    >
                        Load more users
                    </a>
                </div>
            )}


        </Sider>
    );
};

export default ChatSider;
